<template>
  <div class="maxHeight">
    <div class="header flex">
      <div class="title flex">
        <img src="../../../assets/img/ex_a.png" alt="" width="28">
        <h2>笼舍管理</h2>
      </div>
      <el-button size="small" @click="$router.go(-1)">返回上一页</el-button>
    </div>
    <div class="leftVideo common">

      <!--      <video src="" width="100%" height="400"></video>-->
      <camera :data-list="cameraList"></camera>
      <div class="info">
        <p class="number">笼舍编号：{{ cageInfo.number }}</p>
        <p>
          <span>笼架编号：{{ cageInfo.frameNo }}</span>
          <span>动物品系：{{ cageInfo.subject.strain }}</span>
        </p>
        <p>实验名称：{{ cageInfo.subject.name }}</p>
        <p>
          <span>课题负责人：{{ cageInfo.subject.dirNickname }}</span>
          <span >课题状态：
            <label class="colorStyle">
              <template v-if="cageInfo.subject.status==30">去合笼</template>
               <template v-else-if="cageInfo.subject.status==31||cageInfo.subject.status==33">合笼中</template>
              <template v-else-if="cageInfo.subject.status==35">新生动物孵化</template>
              <template v-else-if="cageInfo.subject.status==38||cageInfo.subject.status==39">去结束合笼</template>
              <template v-else-if="cageInfo.subject.status==40">合笼结束</template>

              <template v-else-if="cageInfo.subject.status==18">动物分笼中</template>
              <template v-else-if="cageInfo.subject.status==19">去建模</template>
              <template v-else-if="cageInfo.subject.status==20">建模悬浮液配置中</template>
              <template v-else-if="cageInfo.subject.status==21">建模悬浮液复核中</template>
              <template v-else-if="cageInfo.subject.status==22">去接种</template>
              <template v-else-if="cageInfo.subject.status==43">接种中</template>
              <template v-else-if="cageInfo.subject.status==44">接种复核中</template>
              <template v-else-if="cageInfo.subject.status==23">去药前</template>
              <template v-else-if="cageInfo.subject.status==24">药前进行中</template>
              <template v-else-if="cageInfo.subject.status==25">去分组</template>
              <template v-else-if="cageInfo.subject.status==26">分组中</template>
              <template v-else-if="cageInfo.subject.status==27">实验分组复核中</template>

              <template v-else-if="cageInfo.subject.status==28">去供试品配制</template>
              <template v-else-if="cageInfo.subject.status==29">供试品配制中</template>
              <template v-else-if="cageInfo.subject.status==41">供试品配制复核中</template>
              <template v-else-if="cageInfo.subject.status==50">去给药</template>
              <template v-else-if="cageInfo.subject.status==51">药后记录</template>
              <template v-else-if="cageInfo.subject.status==52">实验终点</template>
              <template v-else-if="cageInfo.subject.status==55">实验终点草稿中</template>
              <template v-else-if="cageInfo.subject.status==56">终点复核中</template>
              <template v-else-if="cageInfo.subject.status==58">去上传实验报告</template>

            </label>
          </span>
        </p>
        <p>实验目的：{{ cageInfo.subject.aim }}</p>
      </div>
    </div>
    <div class="rightInfo common">
      <h3 class="tit">动物信息</h3>
      <div class="maxHeightContent" v-if="cageInfo.subject.isProd==1">
        <template v-for="(item,ind) in cageInfo.mateAniCageList">
          <div class="mt-10" :key="item.id">
            <h4>{{ item.gender == 0 ? '母' : '父' }}本</h4>
            <p>
              <span v-if="item.orderDetail">耳号：{{ item.orderDetail.overbit }}</span>
              <span>出生日期：{{ item.updateTime | formatDay }}</span>
            </p>
            <p>
              <span v-if="item.orderDetail">周龄：{{ item.orderDetail.weekDays }}周</span>
              <span v-if="item.orderDetail">基因类型：<template v-for="type in item.orderDetail.gene">{{
                  type.type
                }}</template></span>
            </p>
            <p>备注信息：{{ item.remark }}</p>
          </div>
          <template v-if="item.mateBirthList.length">
            <div class="mt-10" v-for="(obj,index) in item.mateBirthList" :key="index">
              <h4>新生动物</h4>
              <p>
                <span>雌性数量：{{ obj.femaleNum }}</span>
                <span>雄性数量：{{ obj.maleNum }}</span>
              </p>
            </div>
          </template>

        </template>

      </div>
      <div class="maxHeightContent" v-else>
        <template v-for="(item,ind) in cageInfo.partAniList">
          <div class="mt-10" :key="item.id">
            <h4>{{ item.gender == 0 ? '母' : '父' }}本</h4>
            <p>
              <span >耳号：{{ item.overbit }}</span>
              <span>出生日期：{{ item.birthDate | formatDay }}</span>
            </p>
            <p>
              <span >周龄：{{ item.weekDays }}周</span>
              <span >体重：{{ item.weight }} g</span>
            </p>
            <p>备注信息：{{ item.remark }}</p>
          </div>
        </template>
      </div>
    </div>
    <div style="clear:both;"></div>
  </div>

</template>

<script>
import camera from '@/components/common/camera'

export default {
  name: "cuddlesDetail",
  components: {camera},
  data() {
    return {
      cageId: this.$route.query.id,
      cageInfo: {
        subject: {},
        mateAniCageList: []
      },

    }
  },
  computed: {
    cameraList() {
      return [
        {name: '', devId: this.cageInfo.divId}
      ]
    }
  },
  created() {
    window.addEventListener('beforeunload', e => this.beforeunload(e))
  },
  mounted() {
    this.getData()
  },
  methods: {
    beforeunload(e) {
      // 检测到页面重载事件，直接断开websocket
      this.$store.dispatch('websocket/WEBSOCKET_CLOSE')
    },
    getData() {
      this.$get('/subject/cage/watch/' + this.cageId).then((res) => {
        if (res.status == 200) {
          if (res.data.mateAniCageList) {
            res.data.mateAniCageList.forEach((item, index) => {
              if (item.orderDetail) {
                item.orderDetail.gene = JSON.parse(item.orderDetail.gene)
              }
            })
          }
          this.cageInfo = res.data
          // console.log(res)
        }
      }).catch(err => {
        console.log(err)
      })
    }
  },
}
</script>

<style lang="scss" scoped>
@import "../../../assets/css/exper/cuddles";
</style>
